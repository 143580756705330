import React, { useEffect, useState } from "react"
import imagesloaded from "imagesloaded"
import Layout from "../components/Layout"
import MetaData from "../components/meta"
import { graphql } from "gatsby"

// Import Styles
import "../styles/partners.scss"

// Import Images
import partners__d2iq from "../images/partners/tech/d-2-iq.svg"
import partners__docker from "../images/partners/tech/docker.svg"
import partners__elastic from "../images/partners/tech/elastic.svg"
import partners__instana from "../images/partners/tech/instana.svg"
import partners__microsoft_azure from "../images/partners/tech/microsoft-azure.svg"
import partners__palo_alto from "../images/partners/tech/palo-alto.svg"
import partners__platform9 from "../images/partners/tech/platform-9.svg"
import partners__redhat_openshift from "../images/partners/tech/red-hat-open-shift.svg"
import partners__splunk from "../images/partners/tech/splunk.svg"
import partners__sysdig from "../images/partners/tech/sysdig.svg"
import partners__aws from "../images/partners/cloud/aws.svg"
import partners__do from "../images/partners/cloud/do.svg"
import partners__exoscale from "../images/partners/cloud/exoscale.svg"
import partners__google_cloud from "../images/partners/cloud/google-cloud.svg"
import partners__microsoft from "../images/partners/cloud/microsoft.svg"
import partners__packet from "../images/partners/cloud/packet.svg"
import partners__neoria from "../images/partners/business/neoria.svg"
import partners__ahead from "../images/partners/business/ahead.svg"
import partners__swnetwork from "../images/partners/business/sw-network.svg"
import hashicorp from "../images/partners/tech/hashicorp.svg"

const scrollTo = (id: string) => {
  let position = document.querySelector(id)?.offsetTop - 100 || 0
  window.scroll({
    top: position,
    left: 0,
    behavior: "smooth",
  })
}

const PartnersPage = ({ data, location }) => {
  const [isNavSticky, makeNavSticky] = useState(false)
  const [activeCategory, setActiveCategory] = useState("technology")

  useEffect(() => {
    // Show image once fully loaded
    imagesloaded(
      ".partners-hero",
      {
        background: true,
      },
      function () {
        document.querySelector(".partners-hero")?.classList.remove("bg-hidden")
      }
    )

    // Make partner's nav sticky
    let partners_nav = document.getElementById("partners-nav")
    let partners_nav_anchor = document.getElementById("partners-nav-anchor")
    let site_header = document.querySelector(".site-header")
    let stickyNav = function () {
      if (partners_nav_anchor?.getBoundingClientRect().top <= 50) {
        partners_nav.style.top = site_header?.clientHeight + "px"
        makeNavSticky(true)
        partners_nav_anchor.style.height = partners_nav?.clientHeight
      } else {
        makeNavSticky(false)
        partners_nav_anchor.style.height = 0
      }
    }
    window.addEventListener("scroll", stickyNav)

    // Change category in nav during scroll
    let tech_partners = document.getElementById("anchor-technology-partners")
    let cloud_partners = document.getElementById("anchor-cloud-partners")
    let updateSecondaryNav = function () {
      if (tech_partners?.getBoundingClientRect().top >= 120) {
        setActiveCategory("technology")
      } else if (cloud_partners?.getBoundingClientRect().top >= 120) {
        setActiveCategory("cloud")
      } else {
        setActiveCategory("business")
      }
    }
    window.addEventListener("scroll", updateSecondaryNav)

    // Unmount
    return () => {
      window.removeEventListener("scroll", stickyNav)
      window.removeEventListener("scroll", updateSecondaryNav)
    }
  }, [])

  return (
    <Layout>
      <MetaData
        location={location}
        data={{
          ghostPage: data.current_page,
        }}
      />

      <div className="partners-page">
        {/* Hero */}
        <div className="partners-hero bg-hidden">
          <div className="wrapper-1200">
            <h1>Traefik Labs Partners</h1>
            <p>
              Traefik Labs offers cloud, technology, and business partnerships
              to help you succeed in your cloud-native journey.
            </p>
          </div>
        </div>

        {/* Navigation */}
        <div id="partners-nav-anchor"></div>
        <div
          id="partners-nav"
          className={"partners-nav" + (isNavSticky ? " stick" : "")}
        >
          <div className="wrapper-1200">
            <a
              id="partners-nav-item--tech"
              className={activeCategory === "technology" ? "active" : ""}
              onClick={() => scrollTo(".tech-partners")}
            >
              Technology Partners
            </a>
            <a
              id="partners-nav-item--cloud"
              className={activeCategory === "cloud" ? "active" : ""}
              onClick={() => scrollTo(".cloud-partners")}
            >
              Cloud Partners
            </a>
            <a
              id="partners-nav-item--business"
              className={activeCategory === "business" ? "active" : ""}
              onClick={() => scrollTo(".business-partners")}
            >
              Business Partners
            </a>
          </div>
        </div>

        {/* Technology Partners */}
        <div className="tech-partners" id="technology-partners">
          <div className="wrapper-1200">
            <hr className="hr-tiny" />
            <h2>Technology Partners</h2>
            <p className="sub-title">
              Traefik Labs partners with a wide ecosystem of technology partners
              to provide validated solutions out-of-the-box.
            </p>
            <div className="partner-links">
              <a
                href="mailto:contact@traefik.io"
                className="button--primary button--with-shadow"
              >
                BECOME A TECHNOLOGY PARTNER
              </a>
            </div>

            <div className="partners-grid">
              <button
                onClick={() => (window.location.href = "https://d2iq.com/")}
                className="partners-grid__item"
              >
                <img src={partners__d2iq} alt="D2IQ" />
              </button>

              <button
                onClick={() =>
                  (window.location.href =
                    "https://www.docker.com/products/docker-enterprise")
                }
                className="partners-grid__item"
              >
                <img src={partners__docker} alt="docker" />
              </button>

              <button
                onClick={() =>
                  (window.location.href = "https://www.elastic.co/")
                }
                className="partners-grid__item"
              >
                <img src={partners__elastic} alt="elastic" />
              </button>

              <button
                onClick={() =>
                  (window.location.href = "https://www.instana.com/")
                }
                className="partners-grid__item"
              >
                <img src={partners__instana} alt="instana" />
              </button>

              <button
                onClick={() =>
                  (window.location.href = "https://www.hashicorp.com/")
                }
                className="partners-grid__item"
              >
                <img
                  src={hashicorp}
                  alt="HashiCorp"
                  style={{
                    maxWidth: "180px",
                  }}
                />
              </button>

              <button
                onClick={() =>
                  (window.location.href = "https://www.jaegertracing.io/")
                }
                className="partners-grid__item"
              >
                <img
                  srcSet="/images/partners/tech/jaeger/jaeger.jpg 1x,
                          /images/partners/tech/jaeger/jaeger@2x.jpg 2x,
                          /images/partners/tech/jaeger/jaeger@3x.jpg 3x"
                  src="/images/partners/tech/jaeger/jaeger.jpg"
                  alt="Jaeger"
                />
              </button>

              <button
                onClick={() =>
                  (window.location.href =
                    "https://azuremarketplace.microsoft.com/fr-fr/marketplace/apps/containous.traefikee")
                }
                className="partners-grid__item"
              >
                <img src={partners__microsoft_azure} alt="microsoft azure" />
              </button>

              <button
                onClick={() =>
                  (window.location.href = "https://www.paloaltonetworks.com/")
                }
                className="partners-grid__item"
              >
                <img src={partners__palo_alto} alt="palo alto networks" />
              </button>

              <button
                onClick={() =>
                  (window.location.href = "https://platform9.com/")
                }
                className="partners-grid__item"
              >
                <img src={partners__platform9} alt="platform 9" />
              </button>

              <button
                onClick={() =>
                  (window.location.href = "https://prometheus.io/")
                }
                className="partners-grid__item"
              >
                <img
                  srcSet="/images/partners/tech/prometheus/prometheus.jpg 1x,
                          /images/partners/tech/prometheus/prometheus@2x.jpg 2x,
                          /images/partners/tech/prometheus/prometheus@3x.jpg 3x"
                  src="/images/partners/tech/prometheus/prometheus.jpg"
                  alt="Prometheus"
                />
              </button>

              <button
                onClick={() => (window.location.href = "https://rancher.com/")}
                className="partners-grid__item"
              >
                <img
                  srcSet="/images/partners/tech/rancher/rancher.png 1x,
                          /images/partners/tech/rancher/rancher@2x.png 2x,
                          /images/partners/tech/rancher/rancher@3x.png 3x"
                  src="/images/partners/tech/rancher/rancher.png"
                  alt="rancher"
                />
              </button>

              <button
                onClick={() =>
                  (window.location.href = "https://www.openshift.com/")
                }
                className="partners-grid__item"
              >
                <img
                  src={partners__redhat_openshift}
                  alt="red hat open shift"
                />
              </button>

              <button
                onClick={() =>
                  (window.location.href = "https://www.splunk.com")
                }
                className="partners-grid__item"
              >
                <img src={partners__splunk} alt="splunk" />
              </button>

              <button
                onClick={() => (window.location.href = "https://sysdig.com/")}
                className="partners-grid__item"
              >
                <img src={partners__sysdig} alt="sysdig" />
              </button>
            </div>
          </div>
        </div>
        <div id="anchor-technology-partners"></div>

        {/* Cloud Partners */}
        <div className="cloud-partners" id="cloud-partners">
          <div className="wrapper-1200">
            <hr className="hr-tiny" />
            <h2>Cloud Partners</h2>
            <p className="sub-title">
              Traefik Labs partners with major cloud providers, so you can run
              your applications on the infrastructure of your choice.
            </p>
            <div className="partner-links">
              <a
                href="mailto:contact@traefik.io"
                className="button--primary button--with-shadow"
              >
                BECOME A CLOUD PARTNER
              </a>
            </div>

            <div className="partners-grid">
              <button
                onClick={() =>
                  (window.location.href = "https://aws.amazon.com/")
                }
                className="partners-grid__item"
              >
                <img src={partners__aws} alt="aws" />
              </button>
              <button
                onClick={() =>
                  (window.location.href = "https://www.digitalocean.com/")
                }
                className="partners-grid__item"
              >
                <img src={partners__do} alt="digital ocean" />
              </button>
              <button
                onClick={() =>
                  (window.location.href = "https://www.exoscale.com/")
                }
                className="partners-grid__item"
              >
                <img src={partners__exoscale} alt="exoscale" />
              </button>
              <button
                onClick={() =>
                  (window.location.href = "https://cloud.google.com")
                }
                className="partners-grid__item"
              >
                <img src={partners__google_cloud} alt="google cloud" />
              </button>
              <button
                onClick={() =>
                  (window.location.href = "https://www.microsoft.com/en-us/")
                }
                className="partners-grid__item"
              >
                <img src={partners__microsoft} alt="microsoft" />
              </button>
              <button
                onClick={() =>
                  (window.location.href = "https://www.packet.com/")
                }
                className="partners-grid__item"
              >
                <img src={partners__packet} alt="packet" />
              </button>
            </div>
          </div>
        </div>
        <div id="anchor-cloud-partners"></div>

        {/* Business Partners */}
        <div className="business-partners" id="business-partners">
          <div className="wrapper-1200">
            <hr className="hr-tiny" />
            <h2>Business Partners</h2>
            <p className="sub-title">
              Traefik Labs forms alliances with a select group of system
              integrators, training and consulting companies, and resellers from
              across the globe to deliver premium services to your organization.
            </p>
            <div className="partner-links">
              <a
                href="mailto:contact@traefik.io"
                className="button--primary button--with-shadow"
              >
                BECOME A BUSINESS PARTNER
              </a>
            </div>

            <div className="partners-grid">
              <button
                className="partners-grid__item"
                onClick={() =>
                  (window.location.href = "https://www.thinkahead.com/")
                }
              >
                <img src={partners__ahead} alt="Ahead" />
                <div className="location">United States</div>
              </button>

              <button
                className="partners-grid__item"
                onClick={() =>
                  (window.location.href = "https://www.codecentric.de/")
                }
              >
                <img
                  srcSet="/images/partners/business/codecentric/codecentric.jpg 1x,
                          /images/partners/business/codecentric/codecentric@2x.jpg 2x,
                          /images/partners/business/codecentric/codecentric@3x.jpg 3x"
                  src="/images/partners/business/codecentric/codecentric.jpg"
                  alt="codecentric"
                />
                <div className="location">Germany</div>
              </button>

              <button
                className="partners-grid__item"
                onClick={() =>
                  (window.location.href = "https://www.data-essential.com/")
                }
              >
                <img
                  srcSet="/images/partners/business/data-essential/data-essential.jpg 1x,
                          /images/partners/business/data-essential/data-essential@2x.jpg 2x,
                          /images/partners/business/data-essential/data-essential@3x.jpg 3x"
                  src="/images/partners/business/data-essential/data-essential.jpg"
                  alt="data essential"
                />
                <div className="location">Luxembourg</div>
              </button>

              <button
                className="partners-grid__item"
                onClick={() =>
                  (window.location.href = "https://www.fgts.ru/page/about-en")
                }
              >
                <img
                  srcSet="/images/partners/business/factor-group/customer-logos-factor-group.jpg 1x,
                          /images/partners/business/factor-group/customer-logos-factor-group@2x.jpg 2x,
                          /images/partners/business/factor-group/customer-logos-factor-group@3x.jpg 3x"
                  src="/images/partners/business/factor-group/customer-logos-factor-group.jpg"
                  alt="Factor Group"
                />
                <div className="location">Russia</div>
              </button>

              <button
                className="partners-grid__item"
                onClick={() => (window.location.href = "https://www.kpn.com/")}
              >
                <img
                  srcSet="/images/partners/business/kpn/kpn.jpg 1x,
                          /images/partners/business/kpn/kpn@2x.jpg 2x,
                          /images/partners/business/kpn/kpn@3x.jpg 3x"
                  src="/images/partners/business/kpn/kpn.jpg"
                  alt="KPN"
                />
                <div className="location">The Netherlands</div>
              </button>

              <button
                className="partners-grid__item"
                onClick={() =>
                  (window.location.href = "https://www.linkbynet.com/")
                }
              >
                <img
                  srcSet="/images/partners/business/linkbynet/linkbynet.jpg 1x,
                          /images/partners/business/linkbynet/linkbynet@2x.jpg 2x,
                          /images/partners/business/linkbynet/linkbynet@3x.jpg 3x"
                  src="/images/partners/business/linkbynet/linkbynet.jpg"
                  alt="linkbynet"
                />
                <div className="location">EMEA</div>
              </button>

              <button
                className="partners-grid__item"
                onClick={() => (window.location.href = "https://neoria.be/")}
              >
                <img src={partners__neoria} alt="neoria" />
                <div className="location">Belgium</div>
              </button>

              <button
                className="partners-grid__item"
                onClick={() =>
                  (window.location.href = "https://www.nuaware.com/")
                }
              >
                <img
                  srcSet="/images/partners/business/nuaware/nuaware.jpg 1x,
                          /images/partners/business/nuaware/nuaware@2x.jpg 2x,
                          /images/partners/business/nuaware/nuaware@3x.jpg 3x"
                  src="/images/partners/business/nuaware/nuaware.jpg"
                  alt="nuaware"
                />
                <div className="location">EMEA</div>
              </button>

              <button
                className="partners-grid__item"
                onClick={() =>
                  (window.location.href = "https://www.smartwavesa.com/")
                }
              >
                <img
                  srcSet="/images/partners/business/smartwave/smartwave.jpg 1x,
                          /images/partners/business/smartwave/smartwave@2x.jpg 2x,
                          /images/partners/business/smartwave/smartwave@3x.jpg 3x"
                  src="/images/partners/business/smartwave/smartwave.jpg"
                  alt="smartwave"
                />
                <div className="location">Switzerland</div>
              </button>

              <button
                className="partners-grid__item"
                onClick={() =>
                  (window.location.href = "https://www.swnetwork.de/swnetwork/")
                }
              >
                <img src={partners__swnetwork} alt="SWNetwork" />
                <div className="location">Germany</div>
              </button>

              <button
                className="partners-grid__item"
                onClick={() =>
                  (window.location.href = "https://www.treeptik.com/")
                }
              >
                <img
                  srcSet="/images/partners/business/treeptik/treeptik.png 1x,
                          /images/partners/business/treeptik/treeptik@2x.png 2x,
                          /images/partners/business/treeptik/treeptik@3x.png 3x"
                  src="/images/partners/business/treeptik/treeptik.png"
                  alt="treeptik"
                />
                <div className="location">France</div>
              </button>

              <button
                className="partners-grid__item"
                onClick={() =>
                  (window.location.href = "https://www.zenika.com/")
                }
              >
                <img
                  srcSet="/images/partners/business/zenika/zenika.jpg 1x,
                          /images/partners/business/zenika/zenika@2x.jpg 2x,
                          /images/partners/business/zenika/zenika@3x.jpg 3x"
                  src="/images/partners/business/zenika/zenika.jpg"
                  alt="zenika"
                />
                <div className="location">France</div>
              </button>
            </div>
          </div>
        </div>
        <div id="anchor-business-partners"></div>

        {/* CTA */}
        <div className="partners-cta">
          <div className="wrapper-1200">
            <h2>Partner with Traefik Labs</h2>
            <p className="sub-title">
              If you are interested in partnering with Traefik Labs, please
              contact us to get started.
            </p>
            <div className="partner-links">
              <a
                href="mailto:contact@traefik.io"
                className="button--primary button--with-shadow"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PartnersPage

export const query = graphql`
  query {
    current_page: ghostPage(slug: { eq: "partners" }) {
      ...PageMetaFields
    }
  }
`
